import React from "react"
import { Link, useStaticQuery, graphql } from "gatsby"

import Layout from "../../components/layout/layout"
import SEO from "../../components/seo"
import BigButton from "../../components/buy-equipment/big-buttons"
import WrappingList from "../../components/buy-equipment/wrapping-list"

import styles from "../buy-equipment.module.css"

const ProcessingEquipment = () => {
  const data = useStaticQuery(graphql`
    query {
      allCategory(
        sort: { fields: [name], order: ASC }
        filter: { parentCategorySlug: { eq: "/processing-equipment" } }
      ) {
        nodes {
          name
          slug
        }
      }
    }
  `)
  return (
    <Layout>
      <SEO
        title={`Used Pharmaceutical Equipment & Biotech Instruments For Sale`}
        description={`Surplus Solutions carries a wide variety of used pharmaceutical lab, packaging, and processing equipment. Contact us today for more information on how we can help solve your equipment needs.`}
        />
      <div className="row columns">
        <ul className="nav-buy">
          <li>
            <Link to="/buy-equipment/lab-equipment">Lab Equipment</Link>
          </li>
          <li>
            <Link to="/buy-equipment/packaging-equipment">
              Packaging Equipment
            </Link>
          </li>
          <li className="selected">
            <Link to="/buy-equipment/processing-equipment">
              Processing Equipment
            </Link>
          </li>
        </ul>
        <h1 className="headings mt2 clearfix">Processing Equipment</h1>
        <p>
          Surplus Solutions maintains an extensive selection of processing and
          industrial equipment for use in the chemical, pharmaceutical, food and
          beverage industries. Our inventory includes capsule machines,
          chilsonators, coating pans, dust collectors, extruders, granulators,
          high shear mixers, homogenizers, reactors, tablet inspection and
          tablet presses, and much, much more.
        </p>
        <p>
          Surplus Solutions is a one-stop source for all your processing needs.
          Browse our full inventory below, or contact us to see how we can
          provide customized solutions for your processing equipment needs.
        </p>
      </div>

      <WrappingList containerClassName={styles.container} data={data.allCategory.nodes} slugPrefix={"/catalog"} />

      <BigButton />
    </Layout>
  )
}

export default ProcessingEquipment
